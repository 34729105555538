import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';

const API_URL = process.env.REACT_APP_API_URL; // Adjust this URL to your Flask API's URL

// Create a singleton Axios instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Function to set the Authorization header dynamically
export const setAuthorizationHeader = (token) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setupWebSocketAPI = (userId, workflowId) => {
  console.log('setupWebSocketAPI: ', userId, workflowId);

  // Determine the WebSocket protocol based on the current location protocol
  const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  // Remove the protocol part (http:// or https://)
  const wsBaseUrl = API_URL.replace(/^https?:\/\//, '');
  console.log('TO BE URL HERERERE:', wsBaseUrl);

  const newWs = new WebSocket(`${wsProtocol}//${wsBaseUrl}/ws/${userId}-${workflowId}`);
  return newWs;
};

//Active
export const fetchData = async () => {
  console.log('fetching data...');
  try {
    console.log('calling: ', `${API_URL}/api/data`);
    const response = await api.get(`${API_URL}/api/data`);
    console.log('response in fetching data: ', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

// Fetch user details - active
export const fetchUserDetails = async () => {
  console.log('Fetching user details...');

  try {
    console.log('calling UD: ', `${API_URL}/api/get_user_details`);
    const response = await api.get(`${API_URL}/api/get_user_details`);
    console.log('full response: ', response);

    if (response.data && response.data.status === 'success') {
      console.log('success path');
      return response.data.data;
    } else {
      console.log('error path');
      throw new Error(response.data.message || 'Failed to fetch user details from API');
    }
  } catch (error) {
    console.error('Error fetching user details at API:', error.response || error.message);
    throw error;
  }
};

export const whoAmI = async (accessToken) => {
  try {
    const response = await api.get(`${API_URL}/api/whoami`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

/////////////////////////////////
// WORKFLOWS
/////////////////////////////////

//Creates a new workflow for that user - active
export const createWorkflow = async (authId, title, description, type, typeDescription, workflowData = null) => {
  const requestData = {
    authId,
    title,
    description,
    type,
    type_description: typeDescription,
    workflow_data: workflowData
  };
  console.log('Data being sent to create workflow endpoint:', requestData);

  try {
    const response = await api.post(`${API_URL}/create_workflow`, requestData);
    console.log('new created workflow response:', response);
    // Check if the response status is 201 (Created)
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error creating workflow:', error);
    throw error;
  }
};

//Fetches all workflows belonging to a user - active
export const fetchWorkflows = async (authId) => {
  try {
    const response = await api.get(`${API_URL}/api/workflows?auth_user_id=${authId}`, {
      headers: {
        'Content-Type': 'application/json'
        // Include the Authorization header if your API requires authentication
        //'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (response.data.status === 'success') {
      return response.data.data; // This directly returns the array contained in the 'data' key
    } else {
      throw new Error('Failed to fetch workflows'); // Handle any case where status is not 'success'
    }
  } catch (error) {
    console.error('Error fetching workflows:', error);
    throw error;
  }
};

//fetches a single workflow based on the workflow  - active
export const getWorkflow = async (workflowUUID) => {
  try {
    const response = await api.get(`${API_URL}/api/workflow/${workflowUUID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching workflow:', error);
    throw error;
  }
};

export const createWorkflowRework = async (authId, workflowData) => {
  const requestData = {
    authId,
    workflowData
  };
  try {
    const response = await api.post(`${API_URL}/api/create_workflow`, requestData);
    return response.data;
  } catch (error) {
    console.error('Error creating workflow:', error);
    throw error;
  }
};

//Updates workflow details - active
export const updateWorkflow = async (workflowId, workflowData) => {
  try {
    const response = await api.put(`${API_URL}/api/workflow/${workflowId}`, workflowData);
    return response.data;
  } catch (error) {
    console.error('Error updating workflow:', error);
    throw error;
  }
};

// New function for deleting a workflow
export const deleteWorkflow = async (workflowUUID) => {
  try {
    const response = await api.delete(`${API_URL}/api/delete_workflow/${workflowUUID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting workflow:', error);
    throw error;
  }
};

// Function for copying a workflow
export const copyWorkflow = async (workflowUUID, userUUID, workflowTitle) => {
  try {
    const response = await api.post(`${API_URL}/api/copy_workflow/${workflowUUID}/${userUUID}`, null, {
      params: { workflow_title: workflowTitle }
    });
    return response.data;
  } catch (error) {
    console.error('Error copying workflow:', error);
    throw error;
  }
};

/////////////////////////////////
// STEPS
/////////////////////////////////

//start step - active
export const updateStartNode = async (workflowId, startNodeUUID) => {
  try {
    const response = await api.put(`${API_URL}/api/workflow/${workflowId}/start_node`, { start_node: startNodeUUID });
    return response.data;
  } catch (error) {
    console.error('Error updating start node:', error);
    throw error;
  }
};

//add step or node - active
export const addStep = async (workflowUUID, stepData) => {
  try {
    console.log('workflow UUID in API:', workflowUUID);
    const response = await api.post(`${API_URL}/api/workflow/${workflowUUID}/steps`, stepData);
    return response.data;
  } catch (error) {
    console.error('Error adding step:', error);
    throw error;
  }
};

//delete a step or node: uses UUID - active
export const deleteStep = async (stepId) => {
  try {
    const response = await api.delete(`${API_URL}/api/steps/${stepId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting step:', error);
    throw error;
  }
};

//update step - active
export const updateStep = async (stepId, data) => {
  try {
    const response = await api.put(`${API_URL}/api/step/${stepId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating step:', error);
    throw error;
  }
};

/////////////////////////////////
// EDGES
/////////////////////////////////

//add edge - active
export const addEdgeAPI = async (edge) => {
  try {
    const response = await api.post(`${API_URL}/api/edges`, edge);
    return response.data;
  } catch (error) {
    console.error('Error adding edge:', error);
    throw error;
  }
};

//delete a step or node: uses UUID - active
export const deleteEdge = async (EdgeId) => {
  try {
    console.log('edge ID', EdgeId);
    const response = await api.delete(`${API_URL}/api/edges/${EdgeId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting step:', error);
    throw error;
  }
};

/////////////////////////////////
// SESSIONS & RUNNING
/////////////////////////////////

//Workflow controls
export const runWorkflow = async (workflowId) => {
  //const [debuggerUrl, setDebuggerUrl] = useState('');
  //const [sessionId, setSessionId] = useState('');  // Reintroduce this state
  try {
    //const response = await axios.post(`${API_URL}/api/workflow/${workflowId}/run`);
    const response = await api.get(`${API_URL}/api/start_session/${workflowId}`);

    return response.data;
  } catch (error) {
    console.error('Error running workflow:', error);
    throw error;
  }
};

export const pauseWorkflow = async (sessionId) => {
  try {
    const response = await api.post(`${API_URL}/api/pause_workflow_run/${sessionId}`);
    return response.data;
  } catch (error) {
    console.error('Error pausing workflow:', error);
    throw error;
  }
};

export const stopWorkflow = async (basepilot_session_id) => {
  try {
    const response = await api.post(`${API_URL}/api/stop_workflow_run/${basepilot_session_id}`);
    return response.data;
  } catch (error) {
    console.error('Error stopping workflow:', error);
    throw error;
  }
};

//NOT IN USE
export const continueWorkflow = async (workflowId) => {
  try {
    const response = await api.post(`${API_URL}/api/workflow/${workflowId}/continue`);
    return response.data;
  } catch (error) {
    console.error('Error continuing workflow:', error);
    throw error;
  }
};

// Function to check if there is an active session - active
export const checkActiveSession = async (authId, workflowUUID) => {
  console.log('API check active session: ', authId, workflowUUID);

  try {
    const response = await api.get(`${API_URL}/api/check_browser_session_status/${authId}-${workflowUUID}`);

    console.log('checkActiveSession response:', response.data);

    return response.data;
  } catch (error) {
    console.error('Failed to check active session:', error);
  }
};

// Function to get the browser info for run
export const getBrowserSessionInfo = async (authId, runUUID) => {
  console.log('API check run ID for session: ', authId, runUUID);

  try {
    const response = await api.get(`${API_URL}/api/get_browser_info_for_run/${runUUID}`);

    console.log('getBrowserSessionInfo response:', response.data);

    return response.data;
  } catch (error) {
    console.error('Failed to check active session:', error);
  }
};

// API-key functions
// Fetches API keys for a user - active
export const fetchApiKeys = async (authId) => {
  console.log('Fetching API keys for user:', authId);

  try {
    const response = await api.get(`${API_URL}/get_api_keys/${authId}`);
    console.log('Response:', response);

    const data = response.data;

    if (data.api_keys === 'no_access') {
      return { hasAccess: false, apiKeys: [] };
    } else {
      return { hasAccess: true, apiKeys: data.api_keys };
    }
  } catch (error) {
    console.error('Failed to fetch API keys:', error);
    throw error;
  }
};

// Generates a new API key for the user - active
export const generateApiKey = async (authId, label) => {
  console.log('Generating new API key for user:', authId, 'with label:', label);

  try {
    const response = await api.post(`${API_URL}/generate_api_key`, {
      authId,
      label
    });

    // Check if the response status is OK (status code 200)
    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }

    const result = response.data;
    console.log('API key created:', result);

    return result;
  } catch (error) {
    console.error('Error generating API key:', error);
    throw error;
  }
};

// Deletes an API key for the user - active
export const deleteApiKey = async (authId, apiKey) => {
  console.log('Deleting API key for user:', authId, 'API Key:', apiKey);

  try {
    const response = await api.delete(`${API_URL}/delete_api_key`, {
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        authId,
        apiKey
      }
    });

    // Check if the response status is OK (status code 200)
    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }

    const result = response.data;
    console.log('API key deleted:', result);

    return result;
  } catch (error) {
    console.error('Error deleting API key:', error);
    throw error;
  }
};

// Handling browser sessions - active
export const startSession = async (authId, workflowUUID) => {
  console.log('startSession: ', authId, workflowUUID);
  try {
    const response = await api.get(`${API_URL}/api/start_session/${authId}/${authId}-${workflowUUID}`, {
      // If any data needs to be passed in the body, include it here.
      // In this case, it's not necessary if the body is not required.
    });

    // Axios automatically handles JSON responses, so no need to call response.json()
    return response.data; // Return the response data directly
  } catch (error) {
    console.error('Failed to start session:', error);
    throw error; // Throw the error to be handled by the calling function
  }
};

//stop an active session - active
export const stopSession = async (authId, workflowUUID) => {
  try {
    const response = await api.post(`${API_URL}/api/end_session/${authId}-${workflowUUID}`, {});
    return response.data; // Return the response data directly
  } catch (error) {
    console.error('Failed to end session:', error);
    throw error;
  }
};

export const runPrompt = async (authId, workflowUUID, data) => {
  console.log('runPrompt: ', authId, workflowUUID, data);

  const endpoint = `/api/run_step/${authId}-${workflowUUID}`;

  try {
    // Add the workflowUUID to the data
    data.workflow_id = workflowUUID;

    // Send the data to the API using axios
    const response = await api.post(endpoint, data);

    console.log('runPrompt response:', response);

    // Axios automatically handles JSON responses, so return the parsed data directly
    return response.data;
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const stopWorkflowRun = async (authId, workflowUUID) => {
  try {
    const response = await api.post(`${API_URL}/api/stop_workflow_run/${authId}-${workflowUUID}`, {
      workflowUUID // Pass the workflowUUID directly as the request body
    });

    // Axios automatically handles JSON parsing, so you can return the data directly
    return response.data;
  } catch (error) {
    console.error('Error stopping workflow:', error.response ? error.response.data : error.message);
    throw new Error(`Error stopping workflow: ${error.response ? error.response.statusText : error.message}`);
  }
};

export const runGraphWorkflow = async (authId, workflowUUID, run_mode) => {
  console.log('Run mode from API:', run_mode);
  try {
    const response = await api.post(`${API_URL}/api/run_workflow/${authId}-${workflowUUID}`, {
      workflowUUID, // Pass the workflowUUID directly as the request body
      run_mode
    });

    // Axios automatically handles JSON parsing, so you can return the data directly
    return response.data;
  } catch (error) {
    console.error('Error running workflow graph:', error.response ? error.response.data : error.message);
    throw new Error(`Error running workflow graph: ${error.response ? error.response.statusText : error.message}`);
  }
};

//////////////////////////////////
// Variables
//////////////////////////////////

// Fetch the variables for a workflow - active
export const getVariables = async (workflowId) => {
  const response = await api.get(`${API_URL}/api/workflows/${workflowId}/variables`, {});

  return response.data;
};

// Add a new variable to a workflow - active
export const addVariable = async (workflowUUID, variable) => {
  const response = await api.post(`${API_URL}/api/workflows/${workflowUUID}/variables`, variable);

  return response.data;
};

// updating a constant variable - active
export const updateVariable = async (workflowUUID, updatedVariables) => {
  console.log('making variable update call for workflow:', workflowUUID);
  console.log('making variable update call for workflow:', updatedVariables);

  try {
    const response = await api.put(`${API_URL}/api/workflows/${workflowUUID}/variables`, updatedVariables);
    return response.data;
  } catch (error) {
    console.error('Error updating variables:', error);
    throw error;
  }
};

// Upload a file to a workflow - active
export const uploadFiles = async (workflowUUID, authID, files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file);
  });

  try {
    const response = await api.post(`/upload/${authID}-${workflowUUID}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
};

// Delete a variable from a workflow - active
export const deleteVariable = async (workflowUUID, variableName) => {
  const response = await api.delete(`${API_URL}/api/workflows/${workflowUUID}/variables/${variableName}`, {});
  return response.data;
};

//////////////////////////////////
// Input Variables
//////////////////////////////////

// Fetch input data - active
export const fetchInputData = async (workflow_uuid, run_uuid) => {
  try {
    const response = await api.get(`${API_URL}/api/workflow/${workflow_uuid}/${run_uuid}/get_input_data`);
    console.log('response data', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching input data:', error);
    throw error;
  }
};

//Update input data - active
export const updateInputData = async (workflow_uuid, run_uuid, data) => {
  console.log('data:', data);
  try {
    const response = await api.post(`${API_URL}/api/workflow/${workflow_uuid}/${run_uuid}/update_input_data`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating input data:', error);
    throw error;
  }
};

//Get Runs
export const getWorkflowRuns = async (workflow_uuid) => {
  console.log('making log call for workflow:', workflow_uuid);

  try {
    const response = await api.get(`${API_URL}/api/workflows/${workflow_uuid}/runs`, {
      headers: {
        'Content-Type': 'application/json'
        // Include the Authorization header if your API requires authentication
        //'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });
    console.log('response at API', response);
    console.log('response data', response.data);
    console.log('response status', response.status);

    // Return the response data
    return response.data;
  } catch (error) {
    console.error('Error fetching runs:', error);
    throw error;
  }
};

// Get Run Details
export const getWorkflowRun = async (run_uuid) => {
  console.log('making log call for run:', run_uuid);

  try {
    const response = await api.get(`${API_URL}/api/run/${run_uuid}`, {
      headers: {
        'Content-Type': 'application/json'
        // Include the Authorization header if your API requires authentication
        //'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });
    console.log('response at API', response);
    console.log('response data', response.data);
    console.log('response status', response.status);

    // Return the response data
    return response.data;
  } catch (error) {
    console.error('Error fetching runs:', error);
    throw error;
  }
};

//Update run data
export const updateRun = async (runUUID, runData) => {
  try {
    const response = await api.put(`${API_URL}/api/run/${runUUID}`, runData);
    return response.data;
  } catch (error) {
    console.error('Error updating run:', error);
    throw error;
  }
};

// Create new run
export const createRun = async (workflow_uuid) => {
  try {
    console.log('workflow UUID in API:', workflow_uuid);

    const response = await api.post(`${API_URL}/create_run`, {
      workflow_uuid
    });
    console.log('Full response:', response);
    if (response.status != 200) {
      throw new Error('Network response was not ok');
    }

    return response.data;
  } catch (error) {
    console.error('Error creating run:', error);
    throw error;
  }
};
